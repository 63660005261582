module controllers {
    'use strict';
    export module customs {
        interface IGoodsDeclarationUpdateScope extends ng.IScope { }

        interface IGoodsDeclarationUpdateParams extends ng.ui.IStateParamsService {
            gsdId: number;
            version: number;
            conId: number;
        }

        export class GoodsDeclarationUpdateCtrl {
            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$stateParams',
                'currencyService',
                'classificationValueService',
                'supplierService',
                "$anchorScroll",
                "$state",
                "countryService",
                "bsLoadingOverlayService",
                "lookupService",
                "$timeout",
                '$transitions',
                'authorisedDealerService',
                'warehouseService',
                'cartageContractorService',
                'isInbound',
                'menuService',
                '$uibModal',
                'goodsDeclarationService',
                'accountService',
                'customsPaymentMethodService',
                'valuationCodeService',
                'customsWarehouseService',
                'companyService',
                'customsOfficeCodeService',
                "uiGridConstants",
                "packageTypeService",
                'changeReasonService',
                'procedureCategoryService',
                'customsProcedureMatrixService',
                'customsProcedureMatrixMeasureService',
                'consignmentService',
                'transportAgentService',
                'flowTypeService',
                'rejectionService',
                'prohibitedAndRestrictedService',
                'goodsDeclarationLineService',
                'ediCusresStatusService',
                "incotermService",
                "customsTermsOfPaymentService"
            ];

            public GoodsDeclaration: interfaces.customs.IGoodsDeclaration;
            apiCaseHistoryList: uiGrid.IGridApi;
            apiValuationList: uiGrid.IGridApi;
            gsdNumber: string;
            gsdId: number;
            version: number;
            conId: number;

            selectedTab: number = 0;

            countryList: interfaces.applicationcore.IDropdownModel[] = [];

            CustomsStatus: interfaces.applicationcore.IDropdownModel;
            CreditTerm: interfaces.applicationcore.IDropdownModel

            canEDI: boolean = false;

            hasOverrideReceiptRight: boolean = false;
            hasUpdateExpiryDateRight: boolean = false;  
            hasManugllyCloseConsignmentRight: boolean = false;  
            hasReleaseCreditLimitRight: boolean = false;

            public myHook: any;

            isManual: boolean = false;

            constructor(
                private $scope: IGoodsDeclarationUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $stateParams: IGoodsDeclarationUpdateParams,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private supplierService: interfaces.master.ISupplierService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $state: ng.ui.IStateService,
                public countryService: interfaces.applicationcore.ICountryService,
                public bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,
                private lookupService: interfaces.applicationcore.ILookupService,
                public $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                public authorisedDealerService: interfaces.tariff.IAuthorisedDealerService,
                public warehouseService: interfaces.master.IWarehouseService,
                public cartageContractorService: interfaces.master.ICartageContractorService,
                public isInbound: boolean,
                private menuService: interfaces.applicationcore.IMenuService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private goodsDeclarationService: interfaces.customs.IGoodsDeclarationService,
                private accountService: interfaces.customs.IAccountService,
                private customsPaymentMethodService: interfaces.tariff.ICustomsPaymentMethodService,
                private valuationCodeService: interfaces.tariff.IValuationCodeService,
                private customsWarehouseService: interfaces.tariff.ICustomsWarehouseService,
                private companyService: interfaces.master.ICompanyService,
                private customsOfficeCodeService: interfaces.tariff.ICustomsOfficeCodeService,
                private uiGridConstants: uiGrid.IUiGridConstants,
                private packageTypeService: interfaces.applicationcore.IPackageTypeService,
                private changeReasonService: interfaces.tariff.IChangeReasonService,
                private procedureCategoryService: interfaces.tariff.IProcedureCategoryService,
                private customsProcedureMatrixService: interfaces.tariff.ICustomsProcedureMatrixService,
                private customsProcedureMatrixMeasureService: interfaces.tariff.ICustomsProcedureMatrixMeasureService,
                private consignmentService: interfaces.consignment.IConsignmentService,
                private transportAgentService: interfaces.tariff.ITransportAgentService,
                private flowTypeService: interfaces.customs.IFlowTypeService,
                private rejectionService: interfaces.master.IRejectionService,
                private prohibitedAndRestrictedService: interfaces.tariff.IProhibitedAndRestrictedService,
                private goodsDeclarationLineService: interfaces.customs.IGoodsDeclarationLineService,
                public ediCusresStatusService: interfaces.integration.IEDICusresStatusService,
                public incotermService: interfaces.applicationcore.IIncotermService,
                public customsTermsOfPaymentService: interfaces.tariff.ICustomsTermsOfPaymentService
            ) {
                this.myHook = $transitions.onSuccess({
                    to: '**.Update.GoodsDeclaration',
                    from: '**.Update.GoodsDeclaration.Line'
                }, () => {
                    this.loadGoodsDeclaration();
                    return;
                });

                $scope.$on('$destroy', () => {
                    $transitions.onStart({}, this.myHook)
                });

                if ($stateParams.gsdId === null) {
                    return;
                }

                this.gsdId = $stateParams.gsdId;
                this.version = $stateParams.version;
                this.conId = $stateParams.conId;

                this.loadGoodsDeclaration();
            }

            loadGoodsDeclaration() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.gsd'
                },
                    () => {
                        return this.goodsDeclarationService.getGoodsDeclaration().get({
                            goodsDecId: this.gsdId,
                            version: this.version
                        }, (result: interfaces.customs.IGoodsDeclaration) => {
                            this.GoodsDeclaration = result;
                            this.gsdNumber = this.GoodsDeclaration.Country.Code + " - " + this.GoodsDeclaration.CPCType.Code;

                            if (this.GoodsDeclaration.Source === 'MANUAL'){
                                this.isManual = true;
                            }
                            else{
                                this.isManual = false;
                            }

                            this.gvwCaseHistory.data = this.GoodsDeclaration.CaseHistoryList;
                            this.gvwLines.data = this.GoodsDeclaration.GoodsDeclarationLineList;
                            this.gvwDuties.data = this.GoodsDeclaration.GoodsDeclarationDutyList;
                            this.gvwInvoices.data = this.GoodsDeclaration.GoodsDeclarationInvoiceList;
                            this.gvwValuation.data = this.GoodsDeclaration.GoodsDeclarationValuationList;
                            this.loadCreditTerms();

                            this.getCanEDIRight();
                            this.getHasOverrideReceiptRight();
                            this.getHasUpdateExpiryDateRight();
                            this.getHasManugllyCloseConsignmentRight();
                            this.getHasReleaseCreditLimitRight();
                            
                            this.$timeout(() => {
                                if (this.apiCaseHistoryList) {
                                    this.apiCaseHistoryList.core.notifyDataChange(this.uiGridConstants.dataChange.ALL)
                                }
                                if (this.apiLines) {
                                    this.apiLines.core.notifyDataChange(this.uiGridConstants.dataChange.ALL)
                                }
                            });
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            getCanEDIRight() {
                this.canEDI = false;
                return this.menuService.getGTSConnectMenuItem(696).get((result: interfaces.applicationcore.IMenu) => {
                    if (result && result.name) {
                        this.canEDI = true;

                        if (this.GoodsDeclaration.Source==='MANUAL'){
                            this.canEDI = false;
                        }
                    }
                                        
                }).$promise;
            }

            getHasOverrideReceiptRight() {
                this.hasOverrideReceiptRight = false;
                return this.menuService.getGTSConnectMenuItem(711).get((result: interfaces.applicationcore.IMenu) => {
                    if (result && result.name) {
                        this.hasOverrideReceiptRight = true;
                    }     
                }).$promise;
            }

            getHasUpdateExpiryDateRight() {
                this.hasUpdateExpiryDateRight = false;
                return this.menuService.getGTSConnectMenuItem(712).get((result: interfaces.applicationcore.IMenu) => {
                    if (result && result.name) {
                        this.hasUpdateExpiryDateRight = true;
                    }                                     
                }).$promise;
            }            

            getHasManugllyCloseConsignmentRight() {
                this.hasManugllyCloseConsignmentRight = false;
                return this.menuService.getGTSConnectMenuItem(713).get((result: interfaces.applicationcore.IMenu) => {
                    if (result && result.name) {
                        this.hasManugllyCloseConsignmentRight = true;
                    }                                     
                }).$promise;
            }       
            
            
            getHasReleaseCreditLimitRight() {
                this.hasReleaseCreditLimitRight = false;
                return this.menuService.getGTSConnectMenuItem(716).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.hasReleaseCreditLimitRight = true;
                    }
                }).$promise;
            }    

            ReleaseCreditHold(){
                this.generalService.displayConfirmationBox("Confirm?", `Release Credit Hold?`).then((result) => {
                    if (result) {

                        const goodsDeclarationsDetail: interfaces.customs.GoodsDeclarationDetail = {
                            goodsDeclarationId: this.GoodsDeclaration.Id,
                            comments: null,
                            goodsDeclarationVersion: this.GoodsDeclaration.version
                            
                        }

                        const goodsDeclarations : Array<interfaces.customs.GoodsDeclarationDetail> = [];
                        goodsDeclarations.push(goodsDeclarationsDetail);
        
                            const goodsDeclarationToAction: interfaces.customs.GoodsDeclarationsToAction = { 
                                goodsDeclarations: goodsDeclarations,                    
                            }
                
                        return this.bsLoadingOverlayService.wrap({
                                        referenceId: 'cra.gd'
                                    }, () => {
                                  
                                    return this.goodsDeclarationService.releaseCreditHold().save({}, goodsDeclarationToAction,
                                        (result: interfaces.applicationcore.IMessageHandler) => {
                                            this.generalService.displayMessageHandler(result);
                    
                                            if (!result.HasErrorMessage) {
                                                
                                                this.loadGoodsDeclaration();   
        
                                            }
                                        }).$promise;
                                });
                    }
                });
            }


            loadCountries() {
                return this.countryService.getDropdownList().query({
                },
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        this.countryList = result;
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            loadCustomsStatus(){
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.ediCusresStatusService.getForDropDownByCountry(this.GoodsDeclaration.Country.Id).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            //loadGuaranteeType
            loadGuaranteeType() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.GuaranteeType
                }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                });

                return deferred.promise;
            }

            //loadGuarantor
            loadGuarantor() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.companyService.getCustomsPartyDropdownList(this.GoodsDeclaration.OwnerEntityId, Enum.EnumCustomsPartyType.Guarantor).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            //loadCustomsOfficeOfExit
            loadCustomsOfficeOfExit(searchText: string) {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.customsOfficeCodeService.getForDropdown(searchText, this.GoodsDeclaration.Country.Id).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            loadAgent() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.companyService.getCustomsPartyDropdownList(this.GoodsDeclaration.OwnerEntityId, Enum.EnumCustomsPartyType.Agent).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            loadDeclarant() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.companyService.getCustomsPartyDropdownList(this.GoodsDeclaration.OwnerEntityId, Enum.EnumCustomsPartyType.Declarant).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            loadExporter() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.companyService.getCustomsPartyDropdownList(this.GoodsDeclaration.OwnerEntityId, Enum.EnumCustomsPartyType.Exporter).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            loadImporter() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.companyService.getCustomsPartyDropdownList(this.GoodsDeclaration.OwnerEntityId, Enum.EnumCustomsPartyType.Importer).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            loadWarehouses() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.customsWarehouseService.getForDropdown(this.GoodsDeclaration.Country.Id).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            loadRelatedParty() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.RelatedParty
                }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                });

                return deferred.promise;
            }


            loadAuthorisedDealer() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.authorisedDealerService.getDropdownList(this.GoodsDeclaration.Country.Id).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            loadValuationCode() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.valuationCodeService.getForDropdown(this.GoodsDeclaration.Country.Id).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            loadPaymentMethod() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.customsPaymentMethodService.getForDropdown(this.GoodsDeclaration.Country.Id).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            loadPaymentAccount() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.accountService.getForDropdown(this.GoodsDeclaration.OwnerEntityId, this.GoodsDeclaration.Country.Id, this.GoodsDeclaration.CustomsOfficeOfEntryId).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            //Additional Tab
            loadCustomsCartageContractor(searchText:string) {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.cartageContractorService.getCustomsCartageContractorByDeclarationCountry(this.GoodsDeclaration.OwnerEntityId,this.GoodsDeclaration.Country.Id,searchText).query({
                }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            loadRemoverCartageContractor() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.cartageContractorService.getDropdownList().query({
                    ownerEntityId: this.GoodsDeclaration.OwnerEntityId,
                }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            //loadTransportAgent
            loadTransportAgent() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.transportAgentService.getDropdownList(this.GoodsDeclaration.Country.Id, this.GoodsDeclaration.TransportModeId).query({
                }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            //loadDocumentFlowType
            loadDocumentFlowType() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.flowTypeService.getForDropdown(this.GoodsDeclaration.Country.Id).query({
                }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            loadSuppliers(searchText: string) {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.supplierService.getSupplierDropdown(this.GoodsDeclaration.OwnerEntityId, searchText).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            loadOwner() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.companyService.getCustomsPartyDropdownList(this.GoodsDeclaration.OwnerEntityId, Enum.EnumCustomsPartyType.Owner).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            loadAgencies(){

                var defered = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    defered.resolve([]);
                    return defered.promise;
                }

                this.prohibitedAndRestrictedService.getAgencyDropdown(this.GoodsDeclaration.Country.Id).query({
                    
                }, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, () => {
                    defered.resolve([]);
                });

                return defered.promise;

            }

            loadChangeInitiators(){
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.GoodsDeclarationChangeInitiatedBy
                }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                });

                return deferred.promise;
            }

            loadUnregisteredTraderType() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.UnregisteredTraderType
                }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                });

                return deferred.promise;
            }

            loadEXWarehouse() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.warehouseService.getDropdownList().query({
                    countryId: this.GoodsDeclaration.Country.Id,
                    ownerEntityId: this.GoodsDeclaration.OwnerEntityId,
                    Customs: true
                }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            loadToWarehouse() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.warehouseService.getDropdownList().query({
                    countryId: this.GoodsDeclaration.Country.Id,
                    ownerEntityId: this.GoodsDeclaration.OwnerEntityId,
                    Customs: true
                }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            loadProcessingParty() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.companyService.getCustomsPartyDropdownList(this.GoodsDeclaration.OwnerEntityId, Enum.EnumCustomsPartyType.ProcessingParty).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            loadFunction() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CustomFunction
                }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {

                    if (this.GoodsDeclaration.version > 1) {
                        _.remove(list, (o) => {
                            return o.Id === 384;
                        });
                    }

                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                });

                return deferred.promise;
            }
            
            loadReportingReason() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.rejectionService.getDropdownListByOwnerEntityId(734, this.GoodsDeclaration.OwnerEntityId).query({
                    
                }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                });

                return deferred.promise;
            }

            loadChangeReason(searchText: string) {

                if (!this.GoodsDeclaration) {
                    var deferred = this.$q.defer();
                    deferred.resolve([]);
                    return deferred.promise;
                }

                return this.changeReasonService.getForDropdown(this.GoodsDeclaration.Country.Id).query({
                    countryId: this.GoodsDeclaration.Country.Id
                }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            apiLines: uiGrid.IGridApi;
            gvwLines: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                showGridFooter: true,
                cellEditableCondition: () => {
                    if (this.GoodsDeclaration.LockdownType == 0) {
                        return true;
                    }
                    else {
                        return false;
                    }
                },
                onRegisterApi: (gridApi) => {
                    this.apiLines = gridApi;

                    this.apiLines.cellNav.on.navigate(this.$scope, (gridSelection) => {
                        this.apiLines.selection.selectRow(gridSelection.row.entity);
                    });
                },
                columnDefs: [{
                    name: "EDIT",
                    field: "Id",
                    displayName: "",
                    enableFiltering: false,
                    width: 32,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.goodsDeclarationUpdateCtrl.editLine(MODEL_COL_FIELD)">
                                <span class="fa fa-pencil"></span>
                            </button>
                        </div>`
                }, {
                    name: "SEQNUM",
                    displayName: "#",
                    field: "SequenceNumber",
                    enableFiltering: false,
                    type: "number",
                    cellClass: 'text-right',
                    width: 30,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                }, {
                    name: "COMCODE",
                    displayName: "Commodity Code",
                    field: "CommodityCode",
                    width: 150,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`
                }, {
                    name: "ORGCOUNTRY",
                    displayName: "Origin Country",
                    field: "OriginCountry",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <gts-dropdown ui-select-wrap name="country-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.goodsDeclarationUpdateCtrl.loadCountries(searchText)"></gts-dropdown>
                        </form>`,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (searchTerm.length === 0 || cellValue.Code.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || cellValue.Description.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiLines.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Code < b.Code) return -1;
                                if (a.Code > b.Code) return 1;
                            } else if (direction === "asc") {
                                if (a.Code > b.Code) return 1;
                                if (a.Code < b.Code) return -1;
                            }

                            return 0;
                        }
                    }
                }, {
                    name: "CUSTOMSVAL",
                    displayName: "Customs Value",
                    field: "CustomsValue",
                    width: 150,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`
                }, {
                    name: "CURCODE",
                    displayName: "Currency",
                    field: "CustomsCurrencyCode",
                    width: 75,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`
                }, {
                    name: "TRANSVAL",
                    displayName: "Transaction Value",
                    field: "TransactionValue",
                    width: 150,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`
                }, {
                    name: "TRANSCURCODE",
                    displayName: "Currency",
                    field: "TransactionCurrencyCode",
                    width: 75,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`
                }, {
                    name: "GOODSDESC",
                    displayName: "Description",
                    field: "DescriptionOfGoods",
                    width: 250,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents" uib-popover="{{MODEL_COL_FIELD}}" popover-enable="{{MODEL_COL_FIELD.length >= 50}}" popover-trigger="'mouseenter'" popover-append-to-body="true">{{MODEL_COL_FIELD}}</div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <p class="input-group-sm">
                                <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-desc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                            </p>
                        </form>`,
                }, {
                    name: "STATQTY",
                    displayName: "Statistical Quantity",
                    field: "StatisticalQuantity",
                    width: 150,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <p class="input-group-sm">
                                <input type="number" ignore-wheel name="{{MODEL_COL_FIELD.$$hashKey}}-desc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0"/>
                            </p>
                        </form>`,
                    cellEditableCondition: (scope) => { return scope.row.entity.StatisticalUnitQuantityId; }
                }, {
                    name: "STATUNIT",
                    displayName: "Statistical Unit",
                    field: "StatisticalUnitCode",
                    width: 125,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`
                }, {
                    name: "PREVASSNUM",
                    displayName: "Previous Assessment",
                    field: "PreviousProcedureAssessmentNumber",
                    width: 150,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <p class="input-group-sm">
                                <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-desc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                            </p>
                        </form>`,
                }, {
                    name: "PREVASSLINENUM",
                    displayName: "Previous Assessment #",
                    field: "PreviousProcedureLineNumber",
                    cellClass: 'text-right',
                    cellFilter: 'number: 0',
                    width: 125,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <p class="input-group-sm">
                                <input type="number" ignore-wheel name="{{MODEL_COL_FIELD.$$hashKey}}-desc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="1" />
                            </p>
                        </form>`,
                }, {
                    name: "COUNTQTY",
                    displayName: "Countable Quantity",
                    field: "CountableQuantity",
                    width: 150,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <p class="input-group-sm">
                                <input type="number" ignore-wheel name="{{MODEL_COL_FIELD.$$hashKey}}-desc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.0000001"/>
                            </p>
                        </form>`
                }, {
                    name: "PACKAGETYPE",
                    displayName: "Countable Unit",
                    field: "CountablePackageType",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <gts-dropdown ui-select-wrap name="country-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.goodsDeclarationUpdateCtrl.loadPackageTypes(searchText)" pagination-search="true" refresh-delay="500"></gts-dropdown>
                        </form>`,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (searchTerm.length === 0 || cellValue.Code.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || cellValue.Description.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiLines.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Code < b.Code) return -1;
                                if (a.Code > b.Code) return 1;
                            } else if (direction === "asc") {
                                if (a.Code > b.Code) return 1;
                                if (a.Code < b.Code) return -1;
                            }

                            return 0;
                        }
                    }
                }, {
                    name: "PROCMEASURE",
                    displayName: "Procedure Measure",
                    field: "ProcedureMeasure",
                    width: 150,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`
                }, {
                    name: "REF1",
                    displayName: "Reference 1",
                    field: "Reference1",
                    width: 150,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <p class="input-group-sm">
                                <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-desc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                            </p>
                        </form>`,
                }]
            };

            editLine(dciId: number) {
                if (this.$stateParams["manId"]) {
                    if (this.isInbound) {
                        this.$state.go("auth.Manifests.Update.ConsignmentImport.GoodsDeclaration.Line", { dciId: dciId });
                    } else {
                        this.$state.go("auth.Manifests.Update.ConsignmentExport.GoodsDeclaration.Line", { dciId: dciId });
                    }
                } else {
                    if (this.isInbound) {
                        this.$state.go("auth.ConsignmentImports.Update.GoodsDeclaration.Line", { dciId: dciId });
                    } else {
                        this.$state.go("auth.ConsignmentExports.Update.GoodsDeclaration.Line", { dciId: dciId });
                    }
                }
            }

            //gvwDuties
            apiDuties: uiGrid.IGridApi;
            gvwDuties: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                enableCellEditOnFocus: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                showGridFooter: true,
                onRegisterApi: (gridApi) => {
                    this.apiDuties = gridApi;

                    this.apiDuties.cellNav.on.navigate(this.$scope, (gridSelection) => {
                        this.apiDuties.selection.selectRow(gridSelection.row.entity);
                    });

                    this.$timeout(() => {
                        this.gvwDuties.data = this.GoodsDeclaration.GoodsDeclarationDutyList;
                    });
                },
                columnDefs: [{
                    name: "VIEW",
                    field: "Id",
                    displayName: "",
                    enableFiltering: false,
                    width: 20,
                    visible: false,
                }, {
                    name: "TAXTYPECODE",
                    displayName: "Tax Type Code",
                    field: "TaxTypeCode",
                }, {
                    name: "DUTYTYPECODE",
                    displayName: "Duty Type Code",
                    field: "DutyTypeCode",
                }, {
                    name: "DUTYVAlUE",
                    displayName: "Duty Value",
                    field: "DutyValue",
                    cellClass: 'text-right',
                    cellFilter: 'number: 2'
                }, {
                    name: "DUTYDUE",
                    displayName: "Duty Due",
                    field: "DutiesDue",
                    cellClass: 'text-right',
                    cellFilter: 'number: 2'
                 },{
                    name: "BONDVAL",
                    displayName: "Bond Value",
                    field: "BondValue",
                    cellClass: 'text-right',
                    cellFilter: 'number: 2'
                },]
            };

            recalculateDuties_Click() {
                this.generalService.displayConfirmationBox("Confirm?", `Are you sure you want to recalculate duties on all goods declaration lines?`).then((result) => {
                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'con.update.gsd'
                        }, () => {
                            return this.goodsDeclarationService.recalculateDuties().save((this.GoodsDeclaration),
                                (result: interfaces.applicationcore.IMessageHandler) => {
                                    this.generalService.displayMessageHandler(result);
                                }, (failureMessage) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                                }).$promise;
                        });
                    }
                });
            }

            loadIncoTerms() {
                var defered = this.$q.defer();

                this.incotermService.getDropdownList().query((data: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            loadCustomsTermsOfPayment() {
                var defered = this.$q.defer();

                this.customsTermsOfPaymentService.getForDropdown(this.GoodsDeclaration.Country.Id).query((data: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            invoiceIncotermChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.customs.InvoiceToGoodsDeclarationViewModel){
                if (model){
                    entity.incoterm = model.Code;
                }
                else{
                    entity.incoterm = "";
                }
                this.$timeout(() => {
                    this.apiInvoices.rowEdit.setRowsDirty([entity]);
                });
            }

            invoicePaymentTermsChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.customs.InvoiceToGoodsDeclarationViewModel){
                if (model){
                    entity.termsOfPayment = model.Code;
                }
                else{
                    entity.termsOfPayment = "";
                }
                this.$timeout(() => {
                    this.apiInvoices.rowEdit.setRowsDirty([entity]);
                });
            }

            goodsDecCreditTermChanged(model: interfaces.applicationcore.IDropdownModel){
                if (model){
                    this.GoodsDeclaration.CreditTerm = model.Code;
                }
                else{
                    this.GoodsDeclaration.CreditTerm = "";
                }
            }

            loadCreditTerms() {

                this.customsTermsOfPaymentService.getForDropdown(this.GoodsDeclaration.Country.Id).query((data: Array<interfaces.applicationcore.IDropdownModel>) => {               
                    
                    this.CreditTerm = _.find(data, (o) => {
                        return o.Code === this.GoodsDeclaration.CreditTerm;
                    });
                    
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    
                });
            }

            InvoiceIsActiveChanged(entity){
                if (this.apiInvoices) {
                    this.apiInvoices.rowEdit.setRowsDirty([entity]);
                }
            }

            removeInlineInvoice(entity: interfaces.customs.InvoiceToGoodsDeclarationViewModel) {
                
                if (entity.Id <= 0) {
                    _.remove(this.gvwValuation.data, (o) => {
                        return o === entity;
                    });
                    return;
                }

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.gsd'
                }, () => {
                    return this.goodsDeclarationService.deleteInvoice(entity.Id, this.GoodsDeclaration.Id).delete((result) => {
                        this.$timeout(() => {
                            return this.loadGoodsDeclaration();
                        });
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                });

                _.remove(this.gvwInvoices.data, (o) => {
                    return o === entity;
                });
            }

         


            //gvwInvoices
            apiInvoices: uiGrid.IGridApi;
            gvwInvoices: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: true,
                enableCellEditOnFocus: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                showGridFooter: false,
                onRegisterApi: (gridApi) => {
                    this.apiInvoices = gridApi;

                    this.apiInvoices.cellNav.on.navigate(this.$scope, (gridSelection) => {
                        this.apiInvoices.selection.selectRow(gridSelection.row.entity);
                    });

                    this.$timeout(() => {
                        this.gvwInvoices.data = this.GoodsDeclaration.GoodsDeclarationInvoiceList;
                    });
                },
                columnDefs: [{
                    name: "REMOVE",
                    field: "",
                    displayName: "",
                    enableFiltering: false,
                    width: 55,
                    enableCellEdit: false,
                    headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.goodsDeclarationUpdateCtrl.addInlineInvoice()" 
                            ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0 || grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.Country.Id === 249">
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`,
                    cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.goodsDeclarationUpdateCtrl.removeInlineInvoice(row.entity)" class="btn btn-warning btn-sm" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                                Delete
                            </button>
                        </div>`,
                }, {
                    name: "INVOICENUMBER",
                    displayName: "Invoice Number",
                    field: "invoiceNumber",
                    cellEditableCondition: (scope) => { if(scope.row.entity.invoiceId){return false;} else {return true;}  },
                    editableCellTemplate: `
                        <form name="inputForm">
                            <p class="input-group-sm">
                                <input type="text" ignore-wheel name="invoiceNumber" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control"
                                ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0 || row.entity.invoiceId"
                                ui-grid-editor step="0.01" min="0" />
                            </p>
                        </form>`,
                    width: 150
                }, {
                    name: "INVOICEDATE",
                    displayName: "Invoice Date",
                    field: "invoiceDate",
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    cellEditableCondition: (scope) => { if(scope.row.entity.invoiceId){return false;} else {return true;}  },
                    editableCellTemplate: `
                        <form name="inputForm">
                                <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-endDate"
                                ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0 || row.entity.invoiceId"
                                ></gts-date>
                        </form>`,
                    width: 150
                },
                {
                    name: "incoterm",
                    displayName: "incoterm",
                    field: "",
                    width: 100,
                    enableFiltering: true,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: (scope) => { if(scope.row.entity.invoiceId){return false;} else {return true;}  },
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{row.incoterm}}">{{row.entity.incoterm}}</div>',
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ng-disabled="row.entity.invoiceId" ui-select-wrap name="code-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.goodsDeclarationUpdateCtrl.loadIncoTerms()" required="false" pagination-search="false" refresh-delay="500"
                                                ng-change="grid.appScope.goodsDeclarationUpdateCtrl.invoiceIncotermChanged(model, row.entity)"
                                                ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0 || row.entity.invoiceId"
                                                ></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue, row) => {
                            // Concatenate skuCode and skuDescription to create the display text
                            const displayText = row.entity.incoterm;
                            return (displayText.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB) => {
                        // Concatenate skuCode and skuDescription for both rows
                        const valueA = rowA.entity.incoterm;
                        const valueB = rowB.entity.incoterm;
                
                        // Compare the concatenated strings and return the sorting order
                        if (valueA < valueB) {
                            return -1;
                        } else if (valueA > valueB) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }
                }, 
                {
                    name: "incotermLocation",
                    displayName: "Location",
                    field: "incotermLocation",                    
                    editableCellTemplate: `
                        <form name="inputForm">
                            <p class="input-group-sm">
                                <input type="text" ignore-wheel name="incotermLocation" 
                                ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0"
                                ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor  />
                            </p>
                        </form>`,
                    width: 150
                },                
                {
                    name: "totalInvoiceAmount",
                    displayName: "Total Value",
                    field: "totalInvoiceAmount",
                    type: "number",
                    cellFilter: "number: 2",
                    cellClass: 'text-right',
                    width: 125,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: (scope) => { if(scope.row.entity.invoiceId){return false;} else {return true;}  },                  
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="totalInvoiceAmount" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control"
                                         ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0 || row.entity.invoiceId"
                                        ui-grid-editor step="0.0000000000001" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                },                
                {
                    name: "advancePaymentAmount",
                    displayName: "Advance Payment",
                    field: "advancePaymentAmount",
                    type: "number",
                    cellFilter: "number: 2",
                    cellClass: 'text-right',
                    width: 125,
                    enableCellEditOnFocus: true,        
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="advancePaymentAmount" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control"
                                         ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0"
                                        ui-grid-editor step="0.0000000000001" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                },
                {
                    name: "transactionCurrency",
                    displayName: "Currency",
                    field: "transactionCurrency",
                    width: 80,
                    enableCellEdit: false,
                    cellClass: 'gts-disabled',
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }
                ,
                {
                    name: "termsOfPayment",
                    displayName: "Payment Terms",
                    field: "",
                    width: 100,
                    enableFiltering: true,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{row.incoterm}}">{{row.entity.termsOfPayment}}</div>',
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ui-select-wrap name="code-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.goodsDeclarationUpdateCtrl.loadCustomsTermsOfPayment()" required="false" pagination-search="false" refresh-delay="500"
                                                ng-change="grid.appScope.goodsDeclarationUpdateCtrl.invoicePaymentTermsChanged(model, row.entity)"
                                                ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0"
                                                ></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue, row) => {
                            const displayText = row.entity.termsOfPayment;
                            return (displayText.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB) => {
                        // Concatenate skuCode and skuDescription for both rows
                        const valueA = rowA.entity.termsOfPayment;
                        const valueB = rowB.entity.termsOfPayment;
                
                        // Compare the concatenated strings and return the sorting order
                        if (valueA < valueB) {
                            return -1;
                        } else if (valueA > valueB) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }
                },                                 
                {
                    name: "customsFactor",
                    displayName: "Customs Factor",
                    field: "customsFactor",
                    width: 80,
                    enableCellEdit: false,
                    cellClass: 'text-right gts-disabled',
                    enableCellEditOnFocus: false,
                    cellFilter: "number: 2",                 
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }
                , {
                    name: "ACTIVE",
                    displayName: "Active",
                    field: "isActive",
                    width: 75,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: (scope) => { if(scope.row.entity.invoiceId){return false;} else {return true;}  },     
                    cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0 || row.entity.invoiceId" ng-change="grid.appScope.goodsDeclarationUpdateCtrl.InvoiceIsActiveChanged(row.entity)">',
                    editableCellTemplate: `
                                    <form name="inputForm">
                                        <p class="input-group-sm">
                                            <input type="checkbox" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" ui-grid-editor 
                                            ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0 || row.entity.invoiceId">
                                        </p>
                                    </form>`,
                    filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                        </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return searchTerm == cellValue;
                        }
                    },
                }
            ]
            };

            addInlineInvoice() {
                this.gvwInvoices.data = this.GoodsDeclaration.GoodsDeclarationInvoiceList;
                this.gvwInvoices.data.push(<interfaces.customs.IGoodsDeclarationInvoiceDisplay>{
                    Id: 0,
                    InvoiceDate: undefined,
                    InvoiceNumber: ""
                });
            }



            loadPackageTypes(searchText: string) {
                return this.packageTypeService.getDropdownList(searchText).query((list: Array<interfaces.applicationcore.IDropdownModel>) => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            changeCPCWizard_Click() {
                //Show CPC Wizard
                this.$uibModal.open({
                    animation: true,
                    template: `
                    <form name="ChangeCPCWizard">
                        <div class="modal-content">
                            <div class="gts-padding">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">Select a Customs Procedure</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <div ng-if="Messages.length > 0">
                                        <gts-message-display messages="Messages"></gts-message-display>
                                    </div>
                                    <div class="row vertical-align">
                                        <div class="col-md-5">
                                            <req></req>
                                            <label>Procedure Category</label>
                                        </div>
                                        <div class="col-md-5">
                                            <gts-dropdown name="procedureCategory" ng-model="ProcedureCategory" form="ChangeCPCWizard" load-data="loadProcedureCategory()" ng-change="procedureCategoryChanged(model)" required="true"></gts-dropdown>
                                        </div>
                                    </div>
                                    <div class="row vertical-align">
                                        <div class="col-md-5">
                                            <req></req>
                                            <label>Customs Procedure</label>
                                        </div>
                                        <div class="col-md-5">
                                            <gts-dropdown name="customsProcedure" ng-model="CustomsProcedure" form="ChangeCPCWizard" load-data="loadCustomsProcedure()" required="true" ng-disabled="!ProcedureCategory" list="customsProcedures" ng-change="customsProcedureChanged(model)"  pagination-search="true" refresh-delay="500"></gts-dropdown>
                                        </div>
                                    </div>
                                    <div class="row vertical-align">
                                        <div class="col-md-5">
                                            <req></req>
                                            <label>Previous Customs Procedure</label>
                                        </div>
                                        <div class="col-md-5">
                                            <gts-dropdown name="previousCustomsProcedure" ng-model="PreviousCustomsProcedure" form="ChangeCPCWizard" load-data="loadPreviousCustomsProcedure()" required="true" ng-disabled="!CustomsProcedure" ng-change="previousCustomsProcedureChanged(model)" list="previousCustomsProcedures"></gts-dropdown>
                                        </div>
                                    </div>
                                    <div class="row vertical-align">
                                        <div class="col-md-5">
                                            <opt></opt>
                                            <label>Customs Procedure Matrix Measure</label>
                                        </div>
                                        <div class="col-md-5">
                                            <gts-dropdown name="customsProcedureMatrix" ng-model="CustomsProcedureMatrix" form="ChangeCPCWizard" load-data="loadCustomsProcedureMatrix()" required="false" ng-disabled="!PreviousCustomsProcedure" list="CustomsProcedureMatrixs"></gts-dropdown>
                                        </div>
                                    </div>
                                    <div class="row vertical-align">
                                        <div class="col-md-10">
                                            <textarea rows="3" name="reason" readonly class="form-control">{{ProcedureCategory.Description + ' \n'}}{{CustomsProcedure.Description + ' \n'}}{{PreviousCustomsProcedure.Description + ' \n'}}</textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" ng-click="changeCPC()" ng-disabled="!(ProcedureCategory && CustomsProcedure && PreviousCustomsProcedure)">OK</button>
                                    <button type="button" class="btn btn-info" ng-click="close()">Close</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    `,
                    controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                    ) => {
                        $scope.ProcedureCategory;
                        $scope.CustomsProcedure;
                        $scope.PreviousCustomsProcedure;
                        $scope.CustomsProcedureMatrix;

                        $scope.CustomsProcedureId = undefined;
                        $scope.PreviousCustomsProcedureId = undefined;
                        $scope.CustomsProcedureMatrixCode = undefined;

                        $scope.close = () => {
                            $uibModalInstance.dismiss();
                        }

                        $scope.procedureCategoryChanged = () => {
                            $scope.CustomsProcedure = undefined;
                            $scope.PreviousCustomsProcedure = undefined;
                            $scope.CustomsProcedureMatrix = undefined;

                            $scope.customsProcedures = undefined;
                            $scope.previousCustomsProcedures = undefined;
                            $scope.CustomsProcedureMatrixs = undefined;
                        };

                        $scope.customsProcedureChanged = () => {
                            $scope.PreviousCustomsProcedure = undefined;
                            $scope.previousCustomsProcedures = undefined;
                            $scope.CustomsProcedureMatrix = undefined;
                            $scope.CustomsProcedureMatrixs = undefined;
                        };

                        $scope.previousCustomsProcedureChanged = () => {
                            $scope.CustomsProcedureMatrix = undefined;
                            $scope.CustomsProcedureMatrixs = undefined;
                        };

                        //Load the Dropdowns
                        $scope.loadProcedureCategory = () => {
                            if (!this.GoodsDeclaration) {
                                var deferred = this.$q.defer();
                                deferred.resolve([]);
                                return deferred.promise;
                            }

                            return this.customsProcedureMatrixService.getProcedureCategoryDropdownList(this.GoodsDeclaration.Country.Id).query({
                            }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data, $scope);
                            }).$promise;
                        }

                        $scope.loadCustomsProcedure = (searchText) => {
                            if (!this.GoodsDeclaration && !$scope.ProcedureCategory) {
                                var deferred = this.$q.defer();
                                deferred.resolve([]);
                                return deferred.promise;
                            }

                            return this.customsProcedureMatrixService.getCustomsProcedureDropdownList(this.GoodsDeclaration.Country.Id, $scope.ProcedureCategory.Id).query({
                            }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data, $scope);
                            }).$promise;
                        }

                        $scope.loadPreviousCustomsProcedure = () => {
                            if (!this.GoodsDeclaration) {
                                var deferred = this.$q.defer();
                                deferred.resolve([]);
                                return deferred.promise;
                            }

                            return this.customsProcedureMatrixService.getPrevCustomsProcedureDropdownList(this.GoodsDeclaration.Country.Id, $scope.ProcedureCategory.Id, $scope.CustomsProcedure.Id).query({
                            }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data, $scope);
                            }).$promise;
                        }

                        $scope.loadCustomsProcedureMatrix = () => {
                            if (!this.GoodsDeclaration) {
                                var deferred = this.$q.defer();
                                deferred.resolve([]);
                                return deferred.promise;
                            }

                            return this.customsProcedureMatrixMeasureService.getDropdownList(this.GoodsDeclaration.Country.Id, $scope.ProcedureCategory.Id, $scope.CustomsProcedure.Id, $scope.PreviousCustomsProcedure.Id).query({
                            }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data, $scope);
                            }).$promise;
                        }

                        //Submit
                        $scope.changeCPC = () => {

                            if ($scope.CustomsProcedure) {
                                $scope.CustomsProcedureId = $scope.CustomsProcedure.Id;
                            }

                            if ($scope.PreviousCustomsProcedure) {
                                $scope.PreviousCustomsProcedureId = $scope.PreviousCustomsProcedure.Id;
                            }

                            if ($scope.CustomsProcedureMatrix) {
                                $scope.CustomsProcedureMatrixCode = $scope.CustomsProcedureMatrix.Code;
                            }


                            return this.goodsDeclarationService.changeCPCCode(this.GoodsDeclaration.Id, $scope.ProcedureCategory.Id, $scope.CustomsProcedureId, $scope.PreviousCustomsProcedureId, $scope.CustomsProcedureMatrixCode).get({
                            }, (result: interfaces.applicationcore.IMessageHandler) => {

                                if (!result.HasErrorMessage) {
                                }

                                this.generalService.displayMessageHandler(result);
                                this.$anchorScroll("topAnchor");
                                $uibModalInstance.dismiss();

                                this.$timeout(() => {
                                    this.loadGoodsDeclaration();
                                });
                            }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                                this.$anchorScroll("topAnchor");
                                $uibModalInstance.dismiss();
                            }).$promise;

                        }
                    },
                    controllerAs: "viewCaseHistoryCtrl",
                    size: "lg",
                    resolve: {
                    }
                });
            }

            //Vessel
            vesselSelected(vessel: interfaces.logistics.IVesselLookup) {
                this.GoodsDeclaration.TransportName = vessel.VesselName;
                this.GoodsDeclaration.TransportNumber = vessel.VoyageNumber;
                this.GoodsDeclaration.VesselRadioCallSign = vessel.VesselCallSign;
                this.GoodsDeclaration.VesselOperator = vessel.CustomsOperatorCode;
            }

            SaveGoodsDeclaration() {
                if (this.apiCaseHistoryList) {
                    this.GoodsDeclaration.CaseHistoryList = _.map(this.apiCaseHistoryList.rowEdit.getDirtyRows(), (o) => { return o.entity; });
                } else {
                    this.GoodsDeclaration.CaseHistoryList = [];
                }

                if (this.apiLines) {
                    this.GoodsDeclaration.GoodsDeclarationLineList = _.map(this.apiLines.rowEdit.getDirtyRows(), (o) => { return o.entity; });
                } else {
                    this.GoodsDeclaration.GoodsDeclarationLineList = [];
                }

                if (this.apiInvoices) {
                    this.GoodsDeclaration.GoodsDeclarationInvoiceList = _.map(this.apiInvoices.rowEdit.getDirtyRows(), (o) => { return o.entity; });
                } else {
                    this.GoodsDeclaration.GoodsDeclarationInvoiceList = [];
                }

                if (this.apiValuationList) {
                    this.GoodsDeclaration.GoodsDeclarationValuationList = _.map(this.apiValuationList.rowEdit.getDirtyRows(), (o) => { return o.entity; });
                } else {
                    this.GoodsDeclaration.GoodsDeclarationValuationList = [];
                }                
               
                return this.goodsDeclarationService.save().save({}, this.GoodsDeclaration,
                    (result: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(result);

                        if (!result.HasErrorMessage) {
                            if (this.apiCaseHistoryList) {
                                this.apiCaseHistoryList.rowEdit.setRowsClean(this.GoodsDeclaration.CaseHistoryList);
                            }

                            if (this.apiLines) {
                                this.apiLines.rowEdit.setRowsClean(this.GoodsDeclaration.GoodsDeclarationLineList);
                            }

                            if (this.apiInvoices) {
                                this.apiInvoices.rowEdit.setRowsClean(this.GoodsDeclaration.GoodsDeclarationInvoiceList);
                            }

                            if (this.apiValuationList) {
                                this.apiValuationList.rowEdit.setRowsClean(this.GoodsDeclaration.GoodsDeclarationValuationList);
                            }                            
                        }

                    }, (failureMessage) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                    }).$promise;
            }

            Save() {
                this.generalService.displayConfirmationBox("Confirm", "Save Goods Declaration?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'con.update.gsd'
                        },
                            () => {
                                return this.SaveGoodsDeclaration().then((data) => {
                                    this.$anchorScroll("topAnchor");
                                    if (!data.HasErrorMessage) {
                                        this.$timeout(() => {
                                            this.loadGoodsDeclaration();
                                        });
                                    }
                                }, (data) => {
                                    this.$anchorScroll("topAnchor");
                                });
                            });
                    }
                });
            }

            SaveClose() {
                this.generalService.displayConfirmationBox("Confirm", "Save Goods Declaration?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'con.update.gsd'
                        },
                            () => {
                                return this.SaveGoodsDeclaration().then((data) => {
                                    this.$anchorScroll("topAnchor");
                                    if (!data.HasErrorMessage) {
                                        this.$state.go("^");
                                    }
                                }, (data) => {
                                    this.$anchorScroll("topAnchor");
                                });
                            });
                    }
                });
            }

            Close() {
                this.$state.go("^");
            }

            refresh() {
                if (this.GoodsDeclaration.EDIFACTMessageList && this.GoodsDeclaration.EDIFACTMessageList.length > 0) {
                    this.loadGoodsDeclaration();
                    this.selectedTab = 5;
                }
                else {
                    this.generalService.displayMessage("The goods declaration has not been EDI'd yet", Enum.EnumMessageType.Error);
                    this.$anchorScroll("topAnchor");
                }
            }

            SaveAndValidateGoodsDeclaration() {
                return this.goodsDeclarationService.saveAndValidateEDI().save((this.GoodsDeclaration), (result: interfaces.applicationcore.IMessageHandler) => {

                    if (!result.HasErrorMessage) {
                        //Validated
                    }

                    this.$anchorScroll("topAnchor");
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    this.$anchorScroll("topAnchor");
                }).$promise;
            }

            validateEDI() {
                this.$timeout(() => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'con.update.gsd'
                    }, () => {
                        return this.SaveGoodsDeclaration().then((data) => {
                            this.$anchorScroll("topAnchor");

                            if (!data.HasErrorMessage) {
                                return this.SaveAndValidateGoodsDeclaration().then((result) => {
                                    this.generalService.displayMessageHandler(result);

                                    if (!result.HasErrorMessage) {
                                        this.$timeout(() => {
                                            this.loadGoodsDeclaration();
                                            this.selectedTab = 5;
                                        });
                                    }
                                });
                            } else {
                                this.generalService.displayMessageHandler(data);
                            }
                        }, (data) => {
                            this.$anchorScroll("topAnchor");
                            this.generalService.displayMessageHandler(data);
                        });
                    });
                });
            }

            ValidateAndSendGoodsDeclaration() {
                return this.goodsDeclarationService.validateAndSendEDI().save((this.GoodsDeclaration), (result: interfaces.applicationcore.IMessageHandler) => {

                    if (!result.HasErrorMessage) {
                        //Validated and Sent.
                        this.$timeout(() => {
                            this.loadGoodsDeclaration();
                            this.selectedTab = 5;
                        });
                    }

                    this.$anchorScroll("topAnchor");
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    this.$anchorScroll("topAnchor");
                }).$promise;
            }

            sendEDI() {
                this.$timeout(() => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'con.update.gsd'
                    }, () => {
                        return this.SaveGoodsDeclaration().then((data) => {
                            this.$anchorScroll("topAnchor");

                            if (!data.HasErrorMessage) {
                                return this.ValidateAndSendGoodsDeclaration().then((result) => {
                                    this.generalService.displayMessageHandler(result);
        
                                    if (!result.HasErrorMessage) {
                                        this.$timeout(() => {
                                            this.loadGoodsDeclaration();
                                            this.selectedTab = 5;
                                        });
                                    }
                                });
                            } else {
                                this.generalService.displayMessageHandler(data);
                            }
                        }, (data) => {
                            this.$anchorScroll("topAnchor");
                            this.generalService.displayMessageHandler(data);
                        });
                    });
                });
            }

            RequestEDIResponseResendGoodsDeclaration() {
                return this.goodsDeclarationService.requestEDIResponseResend().get({
                    ownerEntityId: this.GoodsDeclaration.OwnerEntityId,
                    goodsDecId: this.GoodsDeclaration.Id
                }, (result: interfaces.applicationcore.IMessageHandler) => {

                    if (!result.HasErrorMessage) {
                        this.$timeout(() => {
                            this.loadGoodsDeclaration();
                            this.selectedTab = 5;
                        });
                    }

                    this.$anchorScroll("topAnchor");
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    this.$anchorScroll("topAnchor");
                }).$promise;
            }

            requestEDIResponseResend() {
                if (this.GoodsDeclaration.EDIFACTMessageList && this.GoodsDeclaration.EDIFACTMessageList.length > 0) {
                    this.$timeout(() => {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'con.update.gsd'
                        }, () => {
                            return this.RequestEDIResponseResendGoodsDeclaration().then((result) => {

                                this.generalService.displayMessageHandler(result);

                                if (!result.HasErrorMessage) {
                                    this.$timeout(() => {
                                    });
                                }
                            });
                        });
                    });
                }
                else {
                    this.generalService.displayMessage("The goods declaration has not been EDI'd yet", Enum.EnumMessageType.Error);
                    this.$anchorScroll("topAnchor");
                }
            }

            submitDefaultCaseDocuments() {
                return this.goodsDeclarationService.submitDefaultCaseDocuments(this.GoodsDeclaration.Id).save({

                }, (result: interfaces.applicationcore.IMessageHandler) => {
                    this.$anchorScroll("topAnchor");
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    this.$anchorScroll("topAnchor");
                }).$promise;
            }


            submitDefaultCaseDocuments_Click() {
                if (this.GoodsDeclaration.CaseId && this.GoodsDeclaration.CaseNumber) {
                    this.$timeout(() => {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'con.update.gsd'
                        }, () => {
                                return this.submitDefaultCaseDocuments().then((result) => {

                                this.generalService.displayMessageHandler(result);

                                if (!result.HasErrorMessage) {
                                    this.$timeout(() => {
                                    });
                                }
                            });
                        });
                    });
                }
                else {
                    this.generalService.displayMessage("The goods declaration does not have a case associated with it.", Enum.EnumMessageType.Error);
                    this.$anchorScroll("topAnchor");
                }
            }

            //Create Case Click
            createCase_Click(caseId: number) {
                if (caseId) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'con.update.gsd'
                    }, () => {
                        return this.menuService.getGTSConnectMenuItem(190).get((result: interfaces.applicationcore.IMenu) => {
                            if (result.url) {
                                result.url += "?Id=" + caseId;
                                var url = this.$state.href("auth.IFrame", { url: result.url });
                                window.open(url, '_blank');
                            }
                        }).$promise;
                    });
                }
                else {
                    if (this.GoodsDeclaration) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'con.update.gsd'
                        }, () => {
                            return this.menuService.getGTSConnectMenuItem(189).get((result: interfaces.applicationcore.IMenu) => {
                                if (result.url) {
                                    var url = this.$state.href("auth.IFrame", { url: result.url });
                                    window.open(url, '_blank');
                                }
                            }).$promise;
                        });
                    }
                }
            }

            //Open Case History
            caseHistory_Click() {
                this.$uibModal.open({
                    animation: true,
                    template: `
                    <form name="ChangeReason">
                        <div class="modal-content">
                            <div class="gts-padding">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">View Case History</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <div class="row vertical-align">
                                        <div class="col-md-12">
                                            <div id="gsdCaseHistList" ui-grid="gvwCaseHistory" ui-grid-resize-columns ui-grid-selection ui-grid-cellNav ui-grid-edit ui-grid-row-edit class="gridSmallSmall">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-info" ng-click="ok()">Close</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    `,
                    controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                    ) => {
                        $scope.gvwCaseHistory = this.gvwCaseHistory;
                        $scope.ok = () => {
                            $uibModalInstance.dismiss();
                        }

                        $scope.openCase = (id: number) => {
                            this.openCase(id);
                        }
                    },
                    controllerAs: "viewCaseHistoryCtrl",
                    size: "lg",
                    resolve: {
                    }
                });
            }

            gvwCaseHistory: uiGrid.IGridOptions =
            {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableCellEditOnFocus: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: false,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerCaseHistoryGridApi(gridApi); },
                columnDefs: [{
                    name: "VIEW",
                    displayName: "",
                    field: "",
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" ng-click="grid.appScope.openCase(row.entity.Id)" class="btn btn-default btn-sm">
                            <span class="fa fa-eye"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: "CASENUMBER",
                    displayName: "Case Number",
                    field: "CaseNumber",
                    type: "string",
                    cellClass: 'text-right',
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "AGENCY",
                    displayName: "Agency",
                    field: "Agency",
                    type: "string",
                    cellClass: 'text-right',
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "CREATEDDATE",
                    displayName: "Created Date",
                    field: "CreatedDate",
                    type: "string",
                    cellClass: 'text-right',
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                }, {
                    name: "SUBMITTEDDATE",
                    displayName: "Submitted Date",
                    field: "SubmittedDate",
                    type: "string",
                    cellClass: 'text-right',
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                }, {
                    name: "CLOSEDDATE",
                    displayName: "Closed Date",
                    field: "ClosedDate",
                    type: "string",
                    cellClass: 'text-right',
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                } ]
            };

            registerCaseHistoryGridApi(gridApi: uiGrid.IGridApi) {
                this.apiCaseHistoryList = gridApi;

                this.apiCaseHistoryList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiCaseHistoryList.selection.selectRow(gridSelection.row.entity);
                });

                this.$timeout(() => {
                    this.gvwCaseHistory.data = this.GoodsDeclaration.CaseHistoryList;
                });
            }

            openCase(id: number)
            {
                this.createCase_Click(id);
            }

            insertGDL(){
                if (this.$stateParams["manId"]) {
                    if (this.isInbound) {
                        this.$state.go("auth.Manifests.Update.ConsignmentImport.GoodsDeclaration.Line", { dciId: 0 });
                    } else {
                        this.$state.go("auth.Manifests.Update.ConsignmentExport.GoodsDeclaration.Line", { dciId: 0 });
                    }
                } else {
                    if (this.isInbound) {
                        this.$state.go("auth.ConsignmentImports.Update.GoodsDeclaration.Line", { dciId: 0 });
                    } else {
                        this.$state.go("auth.ConsignmentExports.Update.GoodsDeclaration.Line", { dciId: 0 });
                    }
                }
            }

            generateFromInvoice(){

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.gsd'
                }, () => {
                    return this.goodsDeclarationService.generateFromInvoice(this.GoodsDeclaration.Id, this.GoodsDeclaration.version).get({
                    }, (result: interfaces.applicationcore.IMessageHandler) => {

                        if (!result.HasErrorMessage) {
                        }

                        this.generalService.displayMessageHandler(result);
    
                        this.$timeout(() => {
                            this.loadGoodsDeclaration();
                        });
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        this.$anchorScroll("topAnchor");

                    }).$promise;
                });                  
            }

            removeGDL(){
                if (this.apiLines.selection.getSelectedRows().length > 0) {

                    this.generalService.displayConfirmationBox("Confirm Remove", "Are you sure you want to remove this Declaration Line?").then((result) => {
                        var selectedRow: interfaces.customs.IGoodsDeclarationLineDisplay = this.apiLines.selection.getSelectedRows()[0];
                        if (result && (!selectedRow.Id || selectedRow.Id <= 0)) {

                        } else if (result) {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'con.update.gsd'
                            },
                                () => {

                                    return this.goodsDeclarationLineService.removeLine(selectedRow.Id).remove((result) => {
                                        this.generalService.displayMessageHandler(result);
                                        if (!result.HasErrorMessage)
                                            return this.loadGoodsDeclaration();
                                    }, (errorResponse) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    }).$promise;
                                });
                        }
                    });

                } else {
                    this.generalService.displayMessage("Please select a Declaration Line", Enum.EnumMessageType.Warning);
                }
            }

            DisplayPreviousProcedureLineSearch_Click(){
                
                this.DisplayPreviousProcedureLineSearch().then((previousDeclarationLinesGD:Array<interfaces.customs.IPreviousDeclarationLineGD> ) => {
                    if (previousDeclarationLinesGD) {                      
                        return this.bsLoadingOverlayService.wrap({
                                referenceId: 'con.update.com'
                            }, () => {
                        
                                return this.goodsDeclarationLineService.copyLinesFromPreviousProcedureLineGD().save(previousDeclarationLinesGD, (result: interfaces.applicationcore.IMessageHandler) => {
                                    this.$anchorScroll("topAnchor");
                                    this.generalService.displayMessageHandler(result);
                
                                    if (!result.HasErrorMessage) {
                                        this.loadGoodsDeclaration();
                                    }
                                }, (errorResponse) => {
                                    this.$anchorScroll("topAnchor");
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                }).$promise;
                            });
                        }
                    });
            }

            DisplayPreviousProcedureLineSearch(){
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="manualDeclaration">
                                <div class="gts-padding">
                                    <fieldset id="varianceAuth">
                                    <legend>
                                        Previous Procedure Assessment Line Number Search
                                    </legend>    

                                    <uib-accordion>
                                    <div uib-accordion-group class="panel-default" is-open="declarationLineSearchCtrl.searchAccordian">
                                        <uib-accordion-heading>
                                            <span uib-accordion-header="" ng-class="{'text-muted': isDisabled}" class="ng-binding">Advanced Search
                                            </span>
                                        </uib-accordion-heading>                        
        
                                        <div class="container-fluid">
                                            <fieldset>

                                                <div class="row vertical-align">
                                                    <div class="col-md-3">
                                                    <label>Country of Declaration</label>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <p class="input-group-sm">
                                                        <gts-dropdown name="DeclarationCountry" ng-model="declarationLineSearchCtrl.declarationCountry"  load-data="declarationLineSearchCtrl.loadCountries()" ></gts-dropdown>
                                                        </p>
                                                    </div>                                    
                                                </div>

                                                <div class="row vertical-align">
                                                    <div class="col-md-3">
                                                        <label>Consignment Number</label>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <p class="input-group-sm">
                                                            <input autofocus type="text" id="consignmentNumber" ng-model="declarationLineSearchCtrl.consignmentNumber" class="form-control" />
                                                        </p>
                                                    </div>                                    
                                                </div>


                                                <div class="row vertical-align">
                                                    <div class="col-md-3">
                                                        <label>Assessment Number</label>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <p class="input-group-sm">
                                                            <input autofocus type="text" id="assessmentNumber" ng-model="declarationLineSearchCtrl.assessmentNumber" class="form-control" />
                                                        </p>
                                                    </div>                                    
                                                </div>             
                                                
                                                <div class="row vertical-align">
                                                    <div class="col-md-3">
                                                    <label>Ex Warehouse</label>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <p class="input-group-sm">
                                                        <gts-dropdown name="ToWarehouse" ng-model="declarationLineSearchCtrl.dispatchWarehouse"  load-data="declarationLineSearchCtrl.loadToWarehouse()" ></gts-dropdown>
                                                        </p>
                                                    </div>                                    
                                                </div>     
                                                
                                                <div class="row vertical-align">
                                                    <div class="col-md-3">
                                                        <label>Assessment Date From</label>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <p class="input-group-sm">
                                                            <gts-date format="yyyy/MM/dd" ng-model="declarationLineSearchCtrl.AssessmentDateFrom" name="dateFrom"></gts-date>     
                                                        </p>
                                                    </div>                                    
                                                </div>   
                                                
                                                <div class="row vertical-align">
                                                    <div class="col-md-3">
                                                        <label>Assessment Date To</label>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <p class="input-group-sm">
                                                            <gts-date format="yyyy/MM/dd" ng-model="declarationLineSearchCtrl.AssessmentDateTo" name="dateFrom"></gts-date>     
                                                        </p>
                                                    </div>                                    
                                                </div>                                                   

                                            </fieldset>
                                        </div>
        
                                        <button type="submit" class="btn btn-primary"  ng-click="declarationLineSearchCtrl.Search()">Search</button>
                                </div>
                               </uib-accordion>         
                               <div ng-if="!declarationLineSearchCtrl.searchAccordian">
                                            <div class="row vertical-align">
                                                <div class="col-md-2">
                                                
                                                <input type="checkbox" name="manualDeclaration"
                                                    ng-model="declarationLineSearchCtrl.showAll" 
                                                    ng-change="declarationLineSearchCtrl.GetPreviousProcedureGoodsDeclarationLines()"
                                                    />
                                                                                                   
                                                    <opt></opt>
                                                    <label>Show All</label>
                                                </div>
                                                <div class="col-md-4">

                                                <i class="fa fa-question-circle"
                                                        uib-tooltip='If the consignment is from a Bonded Warehouse then the Declarations To Warehouse Customs Code will be filtered by the Customs code of the Dispatch Warehouse, and only where there is available quantity in the Bond Warehouse.'
                                                        tooltip-placement="right">
                                                    </i>  
                                                    
                                                </div>

                                                <div class="col-md-1" style="background-color:#e66a00;height:8px;">

                                                </div>
                                                <div class="col-md-5">
                                                    Indicates previously selected Goods Declaration Line
                                                </div>
                                            </div>

                                            <div id="gvwPreviousProcedureLines" ui-grid="declarationLineSearchCtrl.gvwPreviousProcedureLines" ui-grid-pagination ui-grid-resize-columns ui-grid-selection class="gridFullScreen">
                                                <div layout="column" layout-align="center center" class="grid-msg-overlay" ng-if="!declarationLineSearchCtrl.gvwPreviousProcedureLines.data || declarationLineSearchCtrl.IsLoading">
                                                    <div class="msg" ng-show="(!declarationLineSearchCtrl.gvwPreviousProcedureLines.data ) && !declarationLineSearchCtrl.IsLoading">
                                                        <span>No Rows</span>
                                                    </div>
                                                    <div class="loadingGrid" ng-show="declarationLineSearchCtrl.IsLoading">
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                                                                                                                                

                                            <div class="gts-padding pull-right">                                               
                                            <button type="button" class="btn btn-primary" ng-click="declarationLineSearchCtrl.ok()" ng-disabled = "declarationLineSearchCtrl.disableOkButton">Add</button>
                                            <button type="button" class="btn btn-info" ng-click="declarationLineSearchCtrl.cancel()">Cancel</button>
                                            </div>                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `
                    ,
                    controller: class declarationLineSearchCtrl {

                        paginationOptions = {
                            pageNumber: 1,
                            pageSize: 25
                        };

                        showAll:boolean = false;
                        gridApi: uiGrid.IGridApi;

                        searchAccordian: boolean = true;    

                        sortName: string;
                        sortDirection: string;
                        IsLoading : boolean = false;
                        selectedLine: uiGrid.IGridRow;
                        disableOkButton: boolean = true;
                        selectedPreviousProcedureGoodsDeclarationLineGD:Array<interfaces.customs.PreviousProcedureGoodsDeclarationLineGD> = [];

                        consignmentNumber: string;  
                        assessmentNumber: string;
                        declarationCountry: interfaces.applicationcore.IDropdownModel;
                        AssessmentDateFrom?: moment.Moment;
                        AssessmentDateTo?: moment.Moment;

                        
                        constructor(private $scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,  private $q: ng.IQService,
                            private goodsDeclarationLineService: interfaces.customs.IGoodsDeclarationLineService,
                            private generalService: interfaces.applicationcore.IGeneralService,
                            private $timeout: ng.ITimeoutService,
                            private $uibModal: ng.ui.bootstrap.IModalService,
                            private tariffCountryService: interfaces.tariff.ITariffCountryService,
                            public warehouseService: interfaces.master.IWarehouseService,                            
                            private goodsDeclarationId: number,
                            private DeclarationCountryId: number,
                            private ownerEntityId: number,
                            private dispatchWarehouse? : interfaces.applicationcore.IDropdownModel,
                            ) {
                                this.loadCountries();
                        }

                        
                        Search(){
                            this.GetPreviousProcedureGoodsDeclarationLines();
                        }


                        loadCountries() {
                            return this.tariffCountryService.getActiveTariffCountries().query({
                            }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                                //lookup declarationCountry using DeclarationCountryId
                                if (this.DeclarationCountryId) {
                                    this.declarationCountry = _.find(list, (item) => {
                                        return item.Id === this.DeclarationCountryId;
                                    });
                                }
                            }).$promise;
                        }

                        loadToWarehouse() {
                            var deferred = this.$q.defer();

                            this.warehouseService.getDropdownList().query({
                                countryId: this.DeclarationCountryId,
                                ownerEntityId: this.ownerEntityId,
                                Customs: true
                            }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                                deferred.resolve(list);
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                deferred.resolve([]);
                            });
            
                            return deferred.promise;
                        }

                        GetPreviousProcedureGoodsDeclarationLines(){
                            var searchObject: interfaces.applicationcore.ISearchObject = {
                                filters: [],
                                sorts: []
                            }

                            if (this.gridApi) {
                                var grid = this.gridApi.grid;
                                angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                    if (n.filters[0].term) {
                                        searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                    }
    
                                    if (n.sort.direction) {
                                        searchObject.sorts.push({
                                            Name: n.name,
                                            SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                            SortOrder: n.sort.priority
                                        });
                                    }
                                });
                            }

                            if (this.consignmentNumber) {
                                searchObject.filters.push({ Name: "Consignment", Value: this.consignmentNumber });
                            }

                            if (this.assessmentNumber) {
                                searchObject.filters.push({ Name: "MRN", Value: this.assessmentNumber });
                            }

                            if (this.declarationCountry) {
                                searchObject.filters.push({ Name: "CountryOfDeclarationId", Value: this.declarationCountry.Id });
                            }

                            if(this.dispatchWarehouse){
                                searchObject.filters.push({ Name: "ToWarehouseId", Value: this.dispatchWarehouse.Id });
                            }

                            if(this.AssessmentDateFrom){
                                searchObject.filters.push({ Name: "AssessmentDateFrom", Value: this.AssessmentDateFrom.format("YYYY/MM/DD") });
                            }

                            if(this.AssessmentDateTo){
                                searchObject.filters.push({ Name: "AssessmentDateTo", Value: this.AssessmentDateTo.format("YYYY/MM/DD") });
                            }
                            
                            var params = {
                                connectSearch: searchObject,
                                goodsDeclarationId : this.goodsDeclarationId,
                                showAll : this.showAll,
                                numberRecords: this.paginationOptions.pageSize,
                                pageNumber: this.paginationOptions.pageNumber,
                            }

                            return this.goodsDeclarationLineService.getPreviousProcedureLineGDViewModel().query(
                                params, (result: Array<interfaces.customs.PreviousProcedureGoodsDeclarationLineGD>) => {
                                   
                                    this.gvwPreviousProcedureLines.data = result;

                                    if (result[0]) {
                                        this.gvwPreviousProcedureLines.totalItems = result[0].NumRecords;
                                    } else {
                                        this.gvwPreviousProcedureLines.totalItems = 0;
                                    }

                                    this.searchAccordian = false;

                                    this.gridApi.selection.clearSelectedRows();
                                    for (let item of this.gvwPreviousProcedureLines.data) {
                                        var foundItem = _.find(this.selectedPreviousProcedureGoodsDeclarationLineGD, item as interfaces.customs.PreviousProcedureGoodsDeclarationLineGD);
                                        if (foundItem) {

                                            this.$timeout(() => {
                                                this.gridApi.selection.unSelectRow(item);
                                                this.gridApi.selection.selectRow(item);
                                            });
                                        }
                                    }                                                             
                               
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;

                        }



                        sortChange(gridApi) {
                            this.GetPreviousProcedureGoodsDeclarationLines();
                        }
                        
                        filterChange(gridApi: uiGrid.IGridApi) {
                            this.GetPreviousProcedureGoodsDeclarationLines();
                        }

                        
                        ListPageChange(newPage, pageSize) {

                            this.paginationOptions.pageNumber = newPage;
                            this.paginationOptions.pageSize = pageSize;

                            this.GetPreviousProcedureGoodsDeclarationLines();
                        }

                        public registerGridApi(gridApi: uiGrid.IGridApi) {
                            this.gridApi = gridApi;
            
                            this.gridApi.core.on.filterChanged(this.$scope, () => { this.filterChange(gridApi) });
                            this.gridApi.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                                this.sortChange(gridApi);
                                if (sortColumns[0]) {
                                    this.sortName = sortColumns[0].name;
                                    this.sortDirection = sortColumns[0].sort.direction;
                                }
                                else {
                                    this.sortName = "";
                                    this.sortDirection = "";
                                }
                            });
            
            
                            this.gridApi.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });
            


                            //ROW SELECTION
                            
                            this.gridApi.selection.on.rowSelectionChangedBatch(this.$scope,(rows)=>{
                                
                                for (let selected of rows){
                                    var foundItem = _.find(this.selectedPreviousProcedureGoodsDeclarationLineGD, selected.entity);

                                    if(!foundItem) {
                                        if (selected.isSelected){
                                            this.selectedPreviousProcedureGoodsDeclarationLineGD = _.concat(this.selectedPreviousProcedureGoodsDeclarationLineGD, selected.entity);  
                                        } 
                                    }
                                    else {
                                        _.remove(this.selectedPreviousProcedureGoodsDeclarationLineGD, selected.entity);
                                    }
                                }

                                this.disableOkButton = false;

                                if (this.gridApi.selection.getSelectedRows().length === 0) {
                                    this.disableOkButton = true;                                    
                                }
                                else{
                                    this.disableOkButton = false;
                                }
                            });

                            this.gridApi.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                                this.selectedLine = selected;  
                                var foundItem = _.find(this.selectedPreviousProcedureGoodsDeclarationLineGD, selected.entity);

                                if(!foundItem) {
                                    if (selected.isSelected){
                                        this.selectedPreviousProcedureGoodsDeclarationLineGD = _.concat(this.selectedPreviousProcedureGoodsDeclarationLineGD, selected.entity);  
                                    } 
                                }
                                else {
                                    _.remove(this.selectedPreviousProcedureGoodsDeclarationLineGD, selected.entity);
                                }

                                this.disableOkButton = false;

                                if (this.gridApi.selection.getSelectedRows().length === 0) {
                                    this.disableOkButton = true;                                    
                                }
                                else{
                                    this.disableOkButton = false;
                                }
                            });

                            /*this.$timeout(() => {
                                if(this.selectedPreviousProcedureGoodsDeclarationLineCI){
                                        _.forEach(this.selectedPreviousProcedureGoodsDeclarationLineCI, (line) => {
                                        this.gridApi.selection.selectRow(line);
                                    })
                                }
                            });*/
                        }

                        public gvwPreviousProcedureLines: uiGrid.IGridOptions | any = {
                            data: [],
                            enableSorting: true,
                            enableFiltering: true,
                            useExternalFiltering: true,
                            enableCellEdit: false,
                            useExternalSorting: true,
                            multiSelect: true,
                            enableColumnResizing: true,
                            enableRowSelection: true,
                            enableFullRowSelection: true,
                            enableRowHeaderSelection: true,
                            showGridFooter: true,
                            enableHorizontalScrollbar: 2,
                            enablePinning: false,
                            rowEditWaitInterval: -1,
                            paginationPageSizes: [25, 50, 75, 100],
                            paginationPageSize: 25,
                            enableVerticalScrollbar: 1,
                            useExternalPagination: true,
                            enableGridMenu: false,
                            exporterMenuCsv: false,
                            exporterMenuPdf: false,
                            exporterMenuExcel: false,
                            gridMenuShowHideColumns: false,
                            exporterFieldCallback: (grid, row, col, value) => {
                                return this.generalService.handleValue(value);
                            },
                            exporterSuppressColumns: ['EDIT', 'NUMRECS'],
                            onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                            rowTemplate: `<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, 'SLA-amber': row.entity.PreviouslyLinkedGoodsDeclarationLineId>0}" ui-grid-cell></div>`,
                            columnDefs: [     
                                {
                                    name: "Status",
                                    field: "Status",
                                    displayName: "",
                                    enableFiltering: false,
                                    cellTemplate: `<div style="text-align: center; padding-top: 6px;" >
                                                        <div ng-if="row.entity.Status === 0">
                                                        <span class="fa fa-check-circle" style="color: #bcd856;"
                                                       
                                                        ></span>
                                                    </div>
                                                    <div ng-if="row.entity.Status === 1">
                                                        <span class="fa fa-exclamation-triangle" style="color: #f57f20; opacity: 0.7;"
                                                        uib-tooltip='{{row.entity.Days}} Days in the Bond Warehouse' tooltip-placement="right"
                                                        ></span>
                                                    </div>
                                                    <div ng-if="row.entity.Status === 2">
                                                        <span class="fa fa-times" style="color: #cb2330;"
                                                        uib-tooltip='{{row.entity.Days}} Days in the Bond Warehouse' tooltip-placement="right"
                                                        ></span>
                                                    </div>
                                                    </div>`,
                                    enableSorting: false,
                                    enableColumnMenu: false,
                                    width: 35
                                },                             
                                {
                                    name: "EntityCode",
                                    displayName: "Entity",
                                    field: "EntityCode",
                                    width: 60,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                }, 
                                {
                                    name: "Consignment",
                                    displayName: "Consignment",
                                    field: "Consignment",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },
                                {
                                    name: "CustomsOffice",
                                    displayName: "Customs Office",
                                    field: "CustomsOffice",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },      
                                {
                                    name: "MRN",
                                    displayName: "MRN",
                                    field: "MRN",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                }, 
                                {
                                    name: "AssessmentDate",
                                    displayName: "Assessment Date",
                                    field: "AssessmentDate",
                                    cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                                    width: 140,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="estimateDateOfLoading"></gts-date>
                                            </div>`,
                                },       
                                {
                                    name: "ToWarehouse",
                                    displayName: "To Warehouse",
                                    field: "ToWarehouse",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },       
                                {
                                    name: "ToWarehouseCustomsCode",
                                    displayName: "Customs Code",
                                    field: "ToWarehouseCustomsCode",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },       
                                {
                                    name: "CPC",
                                    displayName: "CPC",
                                    field: "CPC",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },                       
                                {
                                name: "GoodsDecLineNo",
                                displayName: "#",
                                field: "GoodsDecLineNo",
                                width: 40,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },       
                            {
                                name: "CommodityCode",
                                displayName: "Commodity Code",
                                field: "CommodityCode",
                                width: 150,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },       
                            {
                                name: "Origin",
                                displayName: "Origin",
                                field: "Origin",
                                width: 150,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },     
                            {
                                name: "CustomsValue",
                                displayName: "Customs Value",
                                field: "CustomsValue",
                                width: 150,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },
                            {
                                name: "QuantityRemaining",
                                displayName: "Quantity Remaining",
                                field: "QuantityRemaining",
                                width: 180,
                                cellClass: 'text-right',
                                cellFilter: 'number: 2',
                                filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                            },                               
                            {
                                name: "CountableQauntityCode",
                                displayName: "Code",
                                field: "CountableQauntityCode",
                                width: 40,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },                                                             
                            {
                                name: "ProcedureMeasure",
                                displayName: "Procedure Measure",
                                field: "ProcedureMeasure",
                                width: 150,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },
                            {
                                name: "CustomsValue",
                                displayName: "Customs Value",
                                field: "CustomsValue",
                                width: 100,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },                              
                            {
                                name: "StatQty",
                                displayName: "Stat Qty",
                                field: "StatQty",
                                width: 100,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },                            
                            {
                                name: "StatUnit",
                                displayName: "Stat Unit",
                                field: "StatUnit",
                                width: 60,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            }                               
                            ]                
                        }

                        cancel() {
                            this.$uibModalInstance.dismiss();
                        }

                        ok() {

                            const previousDeclarationLinesGD: Array<interfaces.customs.IPreviousDeclarationLineGD> = this.selectedPreviousProcedureGoodsDeclarationLineGD.map(
                                (item) => {
                                  // For each element in the selectedPreviousProcedureGoodsDeclarationLineGF array,
                                  // create a new object of type IPreviousDeclarationLineGD with the required properties.
                                  return {
                                    GoodsDeclarationId : this.goodsDeclarationId,
                                    PreviousProcedureGoodsDeclarationLineId: item.PreviousProcedureGoodsDeclarationLineId,
                                  };
                                });

                                // Create a Set to keep track of the unique keys
                                const uniqueKeys: Set<string> = new Set();

                                // Filter out duplicates and create a new array
                                const filteredPreviousDeclarationLinesCI = previousDeclarationLinesGD.filter((item) => {
                                const key = `${item.GoodsDeclarationId}_${item.PreviousProcedureGoodsDeclarationLineId}`;
                                if (!uniqueKeys.has(key)) {
                                    uniqueKeys.add(key); // Add the key to the Set if it's not already present
                                    return true; // Include the item in the filtered array
                                }
                                return false; // Exclude the item from the filtered array as it's a duplicate
                                });                                

                            this.$uibModalInstance.close(filteredPreviousDeclarationLinesCI);
                        }

                                
                    },
                    controllerAs: "declarationLineSearchCtrl",
                    size: "lg",
                    resolve: {
                         goodsDeclarationId: () => { return this.gsdId },
                         DeclarationCountryId : () => { return this.GoodsDeclaration.Country.Id  }  ,
                         ownerEntityId : () => { return this.GoodsDeclaration.OwnerEntityId },
                         dispatchWarehouse: () => { return this.GoodsDeclaration.EXWarehouse}
                       
                    }
                }).result;
            }

            changeCustomsStatus_Click(){
                
                this.$timeout(() => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'con.update.gsd'
                    }, () => {
                        return this.SaveGoodsDeclaration().then((data) => {
                            this.$anchorScroll("topAnchor");

                            if (!data.HasErrorMessage) {
                                return this.SaveAndValidateGoodsDeclaration().then((result) => {
                                    this.generalService.displayMessageHandler(result);

                                    if (!result.HasErrorMessage) {
                                        this.$timeout(() => {

                                            //this.loadGoodsDeclaration();
                                            //this.selectedTab = 5;

                                            this.goodsDeclarationService.changeCustomsEDIStatus(this.GoodsDeclaration.Id,this.GoodsDeclaration.LastCustomsStatus.Id).save((result) => {
                                                this.generalService.displayMessageHandler(result);
                    
                                                if (!result.HasErrorMessage) {
                                                    this.loadGoodsDeclaration();
                                                }
                                            }, (errorResponse) => {
                                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                            });
                                            
                                        });
                                    }
                                });
                            } else {
                                this.generalService.displayMessageHandler(data);
                            }
                        }, (data) => {
                            this.$anchorScroll("topAnchor");
                            this.generalService.displayMessageHandler(data);
                        });
                    });
                });
            }


            GetCustomsValuationTypes() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.goodsDeclarationService.getCustomsValuationTypes(this.GoodsDeclaration.Country.Id).query({
                }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            getGoodsDeclarationInvoices() {
                var deferred = this.$q.defer();

                if (!this.GoodsDeclaration) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.goodsDeclarationService.getGoodsDeclarationInvoices(this.GoodsDeclaration.Id).query({
                }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            loadCurrencies() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.currencyService.getCurrencyDropdownList().query((currencyList: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(currencyList);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });


                return defered.promise;
            }

            registerValuationGridApi(gridApi: uiGrid.IGridApi) {
                this.apiValuationList = gridApi;                   

                //const customsColumn = this.apiValuationList.grid.getColumn('CustomsValue');
                //customsColumn.displayName = "Customs Value (" + this.GoodsDeclaration.CustomsCurrency.Code + ")";

            }

            addInlineValuation(){
                var valualation = {
                    Id: 0,
                    GoodsDeclarationId : this.GoodsDeclaration.Id,
                    SequenceNumber: 0,
                    Sign: " ",
                    IsActive: true
                };

                (<Array<any>>(this.gvwValuation.data)).splice(0, 0, valualation);
            }

            removeInlineValuation(entity: interfaces.customs.GoodsDeclarationValuation) {
                
                if (entity.Id <= 0) {
                    _.remove(this.gvwValuation.data, (o) => {
                        return o === entity;
                    });
                    return;
                }

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.gsd'
                }, () => {
                    return this.goodsDeclarationService.removeGoodsDeclarationValuation(entity.Id).delete((result) => {
                        this.$timeout(() => {
                            return this.loadGoodsDeclaration();
                        });
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                });

                _.remove(this.gvwInvoices.data, (o) => {
                    return o === entity;
                });
            }

            CustomsValuationTypeChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.logistics.ICommercialInvoiceLineSKUDisplay) {
                
            }

            ValuationRowIsDirty(entity) {
                if (this.apiValuationList) {
                    this.apiValuationList.rowEdit.setRowsDirty([entity]);
                }
            }


            gvwValuation: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                showGridFooter: false,
                onRegisterApi: (gridApi) => { this.registerValuationGridApi(gridApi); },
                columnDefs: [{
                    name: "REMOVE",
                    field: "",
                    displayName: "",
                    enableFiltering: false,
                    width: 55,
                    enableCellEdit: false,
                    headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.goodsDeclarationUpdateCtrl.addInlineValuation()" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`,
                    cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.goodsDeclarationUpdateCtrl.removeInlineValuation(row.entity)" class="btn btn-warning btn-sm" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                                Delete
                            </button>
                        </div>`,
                }, {
                    name: "SequenceNumber",
                    displayName: "#",
                    field: "SequenceNumber",
                    enableFiltering: false,
                    type: "number",
                    cellClass: 'text-right',
                    width: 30,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false
                },  {
                    name: "CustomsValuationType",
                    displayName: "* Cost Type",
                    field: "CustomsValuationType",
                    enableFiltering: true,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                        cellEditableCondition: () => { return this.GoodsDeclaration ? this.GoodsDeclaration.LockdownType < 1 : true },
                    width: 180,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>',
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ng-disabled="row.entity.IsLinked" ui-select-wrap name="code-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.goodsDeclarationUpdateCtrl.GetCustomsValuationTypes()" required="true" pagination-search="false" refresh-delay="1000"
                                                ng-change="grid.appScope.goodsDeclarationUpdateCtrl.CustomsValuationTypeChanged(model, row.entity)"></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiValuationList.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    }
                }, {
                    name: "Description",
                    displayName: "Description",
                    field: "Description",
                    width: 180,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    cellEditableCondition: () => { return this.GoodsDeclaration ? this.GoodsDeclaration.LockdownType < 1 : true },
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-skudesc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                     filter: {
                                condition: (searchTerm, cellValue) => {
                                    return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                                }
                            },                            
                }, 
                {
                    name: "AllocatedInvoice",
                    displayName: "Invoice",
                    field: "AllocatedInvoice",
                    enableFiltering: true,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                        cellEditableCondition: () => { return this.GoodsDeclaration ? this.GoodsDeclaration.LockdownType < 1 : true },
                    width: 130,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>',
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ng-disabled="row.entity.IsLinked" ui-select-wrap name="code-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.goodsDeclarationUpdateCtrl.getGoodsDeclarationInvoices()" required="false" pagination-search="false" refresh-delay="1000"
                                                ></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiValuationList.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    }
                },
                {
                    name: "Dutiable",
                    displayName: "Dutiable",
                    field: "Dutiable",
                    width: 60,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellTemplate: `<div class="ui-grid-cell-contents" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">{{MODEL_COL_FIELD ? 'Yes' : 'No'}}</div>`,
                    editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" name="{{MODEL_COL_FIELD.$$hashKey}}-desc" ng-click="grid.appScope.goodsDeclarationUpdateCtrl.ValuationRowIsDirty(row.entity)" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0" ng-model="MODEL_COL_FIELD">
                                                    </p>
                                                </form>`,
                   filterHeaderTemplate: `
                                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                    <div class="input-group-sm">
                                                        <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                </div>`,
                    filter: {
                                condition: (searchTerm, cellValue) => {
                                    return searchTerm == cellValue;
                                }
                            }
                },
                {
                    name: "IncludedInPrice",
                    displayName: "Included In Price",
                    field: "IncludedInPrice",
                    width: 60,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellTemplate: `<div class="ui-grid-cell-contents" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">{{MODEL_COL_FIELD ? 'Yes' : 'No'}}</div>`,
                    editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" name="{{MODEL_COL_FIELD.$$hashKey}}-desc" ng-click="grid.appScope.goodsDeclarationUpdateCtrl.ValuationRowIsDirty(row.entity)" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0" ng-model="MODEL_COL_FIELD">
                                                    </p>
                                                </form>`,
                    filterHeaderTemplate: `
                                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                    <div class="input-group-sm">
                                                        <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                </div>`,
                    filter: {
                                condition: (searchTerm, cellValue) => {
                                    return searchTerm == cellValue;
                                }
                            }                                              
                },                
                {
                    name: "Sign",
                    displayName: "Sign",
                    field: "Sign",
                    width: 50,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="sellingValue" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            //remove \ from searchTerm
                            searchTerm = searchTerm.replace(/\\/g, '');
                            return cellValue === searchTerm;
                        }
                    }
                },
                {
                    name: "Amount",
                    displayName: "* Amount",
                    field: "Amount",
                    type: "number",
                    cellFilter: "number: 2",
                    cellClass: 'text-right',
                        width: 115,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: () => { return this.GoodsDeclaration ? this.GoodsDeclaration.LockdownType < 1 : true },
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType >= 1 " name="amount" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.0000000000001" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue === searchTerm;
                        }
                    }
                }, 
                {
                    name: "Currency",
                    displayName: "Currency",
                    field: "Currency",
                    enableFiltering: true,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                        cellEditableCondition: () => { return this.GoodsDeclaration ? this.GoodsDeclaration.LockdownType < 1 : true },
                    width: 85,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>',
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ng-disabled="row.entity.IsLinked" ui-select-wrap name="code-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.goodsDeclarationUpdateCtrl.loadCurrencies()" required="false" pagination-search="false" refresh-delay="1000"
                                                ></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiValuationList.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    }
                },
                {
                    name: "ExchangeRate",
                    displayName: "* Exchange Rate",
                    field: "ExchangeRate",
                    type: "number",
                    cellFilter: "number: 5",
                    cellClass: 'text-right',
                        width: 120,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: () => { return this.GoodsDeclaration ? this.GoodsDeclaration.LockdownType < 1 : true },
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType >= 1 " name="ExchangeRate" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.0000000000001" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue === searchTerm;
                        }
                    }
                },
                {
                    name: "CustomsValue",
                    displayName:  "Customs Value",
                    field: "CustomsValue",
                    type: "number",
                    cellFilter: "number: 2",
                    cellClass: 'text-right',
                        width: 115,
                        enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="sellingValue" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.0000000000001" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue === searchTerm;
                        }
                    }
                }
                , {
                    name: "ACTIVE",
                    displayName: "Active",
                    field: "IsActive",
                    width: 70,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0" ng-change="grid.appScope.goodsDeclarationUpdateCtrl.ValuationRowIsDirty(row.entity)">',
                    editableCellTemplate: `
                                    <form name="inputForm">
                                        <p class="input-group-sm">
                                            <input type="checkbox" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" ui-grid-editor ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                                        </p>
                                    </form>`,
                    filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                        </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return searchTerm == cellValue;
                        }
                    },
                }
             ]
            };

            proRataOverInvoices_Click(){
                this.generalService.displayConfirmationBox("Confirm", "Pro Rata over Invoices?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'con.update.gsd'
                        },
                            () => {
                                return this.goodsDeclarationService.proRataValuationOverInvoices(this.GoodsDeclaration.Id).save(
                                    (result: interfaces.applicationcore.IMessageHandler) => {
                                        this.generalService.displayMessageHandler(result);
    
                                        if (!result.HasErrorMessage) {
                                            this.$timeout(() => {
                                                this.loadGoodsDeclaration();
                                            });
                                        }
    
                                    }, (failureMessage) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                                    }).$promise;
                            });
                    }
                });
            }

            recalculateValuation_Click(){
                this.generalService.displayConfirmationBox("Confirm", "Recaculate Valuation?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'con.update.gsd'
                        },
                            () => {
                                return this.goodsDeclarationService.recalculateValuation(this.GoodsDeclaration.Id).save(
                                (result: interfaces.applicationcore.IMessageHandler) => {
                                    this.generalService.displayMessageHandler(result);

                                    if (!result.HasErrorMessage) {
                                        this.$timeout(() => {
                                            this.loadGoodsDeclaration();
                                        });
                                    }

                                }, (failureMessage) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                                }).$promise;
                            });
                    }
                });
            }

            proCalculateCustomsFactor_Click(){
                this.generalService.displayConfirmationBox("Confirm", "Calculate Customs Factor?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'con.update.gsd'
                        },
                            () => {
                                return this.goodsDeclarationService.calculateCustomsFactor(this.GoodsDeclaration.Id).save(
                                (result: interfaces.applicationcore.IMessageHandler) => {
                                    this.generalService.displayMessageHandler(result);

                                    if (!result.HasErrorMessage) {
                                        this.$timeout(() => {
                                            this.loadGoodsDeclaration();
                                        });
                                    }

                                }, (failureMessage) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                                }).$promise;
                            });
                    }
                });
            }

            updateReceiptNumberAndDate(){
                this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `
                        <div class="modal-header" style="white-space: nowrap;">
                            <h3 class="modal-title" id="modal-title">Update Receipt Number & Date</h3>
                        </div>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="gd.modal">
                            <form name="cominvexcelupload">
                                <div class="gts-padding">
                                    <gts-message-display messages="modalPopupCtrl.messages"></gts-message-display>
                                    <div class="modal-body" id="modal-body">
                                        <div class="gts-padding">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <req></req>
						                            <label>Receipt Number</label>
					                            </div>
					                            <div class="col-md-6">
                                                    <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="modalPopupCtrl.receiptNumber" /></div>
                                                    </div>
					                            </div>
				                            </div>
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <req></req>
						                            <label>Receipt Date</label>
					                            </div>
					                            <div class="col-md-6">
                                                <gts-date format="yyyy/MM/dd" ng-model="modalPopupCtrl.receiptDate" name="receiptDate"                                                    
                                                ></gts-date>
					                            </div>
				                            </div>                                            
			                            </div>                                          
                                    </div>
                                    <div class="modal-footer"> 
                                        <button class="btn btn-primary" type="button"  ng-disabled="!modalPopupCtrl.receiptNumber || !modalPopupCtrl.receiptDate" ng-click="modalPopupCtrl.Submit()">Submit</button>
                                        <button class="btn btn-info" type="button"  ng-click="modalPopupCtrl.cancel()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        `,
                    controller: class modalPopupCtrl {

                        messages: interfaces.applicationcore.IMessage[] = [];
                        disableSubmit: boolean = false;
                        receiptNumber:string;
                        receiptDate: moment.Moment;
                      
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private goodsDeclarationService: interfaces.customs.IGoodsDeclarationService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            public bsLoadingOverlayService,
                            public ENV: interfaces.applicationcore.serverConfig,
                            private goodsDeclarationId: number
                        ) {

                         
                        }
                 
                        Submit(){

                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'gd.modal'
                            },
                                () => {

                                  return   this.goodsDeclarationService.updateReceiptNumberAndDate(this.goodsDeclarationId,this.receiptNumber,this.receiptDate.format("YYYYMMDDTHH:mm")).save((result) => {
                                    this.generalService.displayMessageHandler(result);
            
                                    if (!result.HasErrorMessage) {
                                        this.disableSubmit = false;
                                        this.$uibModalInstance.close(true);
                                    }
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                })                              

                                });
                    
                        }

                        cancel() {
                            this.$uibModalInstance.close(false);
                        }
 
 
                    },
                    controllerAs: "modalPopupCtrl",
                    resolve: { goodsDeclarationId : this.GoodsDeclaration.Id
                    }
                }).result.then((result) => {
                    if (result) {
                        this.loadGoodsDeclaration();
                    }
                });
            }

            updateExpiryDate(){
                this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `
                        <div class="modal-header" style="white-space: nowrap;">
                            <h3 class="modal-title" id="modal-title">Update Expiry Date</h3>
                        </div>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="gd.modal">
                            <form name="cominvexcelupload">
                                <div class="gts-padding">
                                    <gts-message-display messages="modalPopupCtrl.messages"></gts-message-display>
                                    <div class="modal-body" id="modal-body">
                                        <div class="gts-padding">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <req></req>
						                            <label>Expiry Date</label>
					                            </div>
					                            <div class="col-md-6">
                                                <gts-date format="yyyy/MM/dd" ng-model="modalPopupCtrl.expiryDate" name="expiryDate"                                                    
                                                ></gts-date>
					                            </div>
				                            </div>                                            
			                            </div>                                          
                                    </div>
                                    <div class="modal-footer"> 
                                        <button class="btn btn-primary" type="button"  ng-disabled="!modalPopupCtrl.expiryDate" ng-click="modalPopupCtrl.Submit()">Submit</button>
                                        <button class="btn btn-info" type="button"  ng-click="modalPopupCtrl.cancel()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        `,
                    controller: class modalPopupCtrl {

                        messages: interfaces.applicationcore.IMessage[] = [];
                        disableSubmit: boolean = false;
                        receiptNumber:string;
                        expiryDate: moment.Moment;
                      
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private goodsDeclarationService: interfaces.customs.IGoodsDeclarationService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            public bsLoadingOverlayService,
                            public ENV: interfaces.applicationcore.serverConfig,
                            private goodsDeclarationId: number
                        ) {

                         
                        }
                 
                        Submit(){

                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'gd.modal'
                            },
                                () => {

                                  return   this.goodsDeclarationService.updateExpiryDate(this.goodsDeclarationId,this.expiryDate.format("YYYYMMDDTHH:mm")).save((result) => {
                                    this.generalService.displayMessageHandler(result);
            
                                    if (!result.HasErrorMessage) {
                                        this.disableSubmit = false;
                                        this.$uibModalInstance.close(true);
                                    }
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                })                              

                                });
                    
                        }

                        cancel() {
                            this.$uibModalInstance.close(false);
                        }
 
 
                    },
                    controllerAs: "modalPopupCtrl",
                    resolve: { goodsDeclarationId : this.GoodsDeclaration.Id
                    }
                }).result.then((result) => {
                    if (result) {
                        this.loadGoodsDeclaration();
                    }
                });
            }

            manuallyCloseGoodsDeclaration(){
                this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `
                        <div class="modal-header" style="white-space: nowrap;">
                            <h3 class="modal-title" id="modal-title">Manually Close Goods Declaration</h3>
                        </div>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="gd.modal">
                            <form name="cominvexcelupload">
                                <div class="gts-padding">
                                    <gts-message-display messages="modalPopupCtrl.messages"></gts-message-display>
                                    <div class="modal-body" id="modal-body">
                                        <div class="gts-padding">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <req></req>
						                            <label>Comment</label>
					                            </div>
					                            <div class="col-md-6">
                                                <p class="input-group-sm has-feedback" >
                                                    <textarea rows="3" name="Reason" ng-model="modalPopupCtrl.comments" class="form-control"></textarea>
                                                </p>
					                            </div>
				                            </div>                                            
			                            </div>                                          
                                    </div>
                                    <div class="modal-footer"> 
                                        <button class="btn btn-primary" type="button"   ng-click="modalPopupCtrl.Submit()">Submit</button>
                                        <button class="btn btn-info" type="button"  ng-click="modalPopupCtrl.cancel()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        `,
                    controller: class modalPopupCtrl {

                        messages: interfaces.applicationcore.IMessage[] = [];
                        disableSubmit: boolean = false;
                        receiptNumber:string;
                        comments: string;
                      
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private goodsDeclarationService: interfaces.customs.IGoodsDeclarationService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            public bsLoadingOverlayService,
                            public ENV: interfaces.applicationcore.serverConfig,
                            private goodsDeclarationId: number
                        ) {

                         
                        }
                 
                        Submit(){

                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'gd.modal'
                            },
                                () => {

                                  return   this.goodsDeclarationService.manuallyCloseGoodsDeclaration(this.goodsDeclarationId,this.comments).save((result) => {
                                    this.generalService.displayMessageHandler(result);
            
                                    if (!result.HasErrorMessage) {
                                        this.disableSubmit = false;
                                        this.$uibModalInstance.close(true);
                                    }
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                })                              

                                });
                    
                        }

                        cancel() {
                            this.$uibModalInstance.close(false);
                        }
 
 
                    },
                    controllerAs: "modalPopupCtrl",
                    resolve: { goodsDeclarationId : this.GoodsDeclaration.Id
                    }
                }).result.then((result) => {
                    if (result) {
                        this.loadGoodsDeclaration();
                    }
                });
            }


        }

        angular.module("app").controller("goodsDeclarationUpdateCtrl", controllers.customs.GoodsDeclarationUpdateCtrl);
    }
}